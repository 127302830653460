import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/Home.css";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import C_Card from "../Cards/C_Card/C_Card";
import Toolbar from "../ToolBar/Toolbar";

function Category() {
  return (
    <>
      <Navbar />
      <div className="container-fluid px-0">
        {/* Breadcrumb Section */}
        <div className="row mt-3 mb-3">
          <div className="col-10">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link id="link" to="/Category">
                  Category
                </Link>
              </li>
            </ol>
          </div>
          <div className="col-2">
            <FontAwesomeIcon id="bell" icon={faBell} />
          </div>
        </div>

        {/* Category List */}
        <div className="container-fluid">
          <div className="row">
            {window.Category.map((item, index) => (
              <div key={index} className="col-6">
                {/* Pass the cat_id as a query parameter */}
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/Pro_Page?cat_id=${item.cat_id}`}
                >
                  <C_Card
                    key={index}
                    imgSrc={`assets/Category/${item.img}`}
                    text={item.name}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div style={{ height: 24 }}>&nbsp;</div>
      <Toolbar />
    </>
  );
}

export default Category;
