import "bootstrap/dist/css/bootstrap.min.css";
import "./LocBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function LocBar() {
  return (
    <>
      {/* section 2 */}
      <div className="row align-items-center location-row mt-4 mb-4">
        <div className="col-2">
          <FontAwesomeIcon icon={faLocationDot} className="location-icon" />
        </div>
        <div className="col-8 LocText">
          <p className="location-text">Your Location</p>
          <p className="location-address mb-0">
            32 Llanberis Close, Tonteg, CF38 1HR
          </p>
        </div>
        <div className="col-2">
          <Link to={"/MyAddress"}>
            <button className="arrow">
              <FontAwesomeIcon icon={faChevronRight} className="icon" />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default LocBar;
