import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/Home.css";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import P_Card from "../Cards/P_Card/P_Card";
import LocBar from "../LocationBar/LocBar";
import SearchCard from "../Cards/SearchCard/SearchCard";
function Home() {
  const [isSearchActive, setIsSearchActive] = useState(false);

  return (
    <>
      <Navbar />
      <div className="container-fluid px-0">
        {/* section 1 */}
        <div className="row mt-3">
          <div className="col-10">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link id="link" to="/Home">
                  Home
                </Link>
              </li>
            </ol>
          </div>
          <div className="col-2">
            <Link to={"/Notification"}>
              <FontAwesomeIcon id="bell" icon={faBell} />
            </Link>
          </div>
        </div>

        {/* section 2 */}
        <LocBar />

        {/* section 3 */}
        <div className="search">
          <div className="input-group">
            <span className="input-group-text" id="search-icon">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            <input
              type="text"
              className="SearchInput form-control"
              placeholder="Search"
              aria-label="Search"
              onFocus={() => setIsSearchActive(true)} // Show SearchDiv on focus
              onBlur={(e) => {
                if (
                  !e.target.className === "SearchInput" &&
                  !e.target.className === "SearchDiv"
                ) {
                  setIsSearchActive(false);
                }
                // else {
                //   setTimeout(() => setIsSearchActive(false), 200);
                // }
              }} // Hide SearchDiv on blur
            />
          </div>
          <div className={`SearchDiv ${isSearchActive ? "active" : ""}`}>
            <SearchCard ProName={"Product Name"} />
            <SearchCard ProName={"Product Name"} />
            <SearchCard ProName={"Product Name"} />
            <SearchCard ProName={"Product Name"} />
          </div>
        </div>

        {/* section 4 */}
        <div className="container-fluid">
          <div className="row">
            {window.Product.map((item, index) => (
              <P_Card
                key={index}
                imgSrc={`assets/Product/${item.img}`}
                alt={item.alt}
                text={item.name}
                price={item.price}
              />
            ))}
          </div>
        </div>
      </div>
      <Toolbar />
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default Home;
