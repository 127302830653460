import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Splash from './Components/Splash/Splash';
import Home from './Components/Home/Home';
import Details from './Components/Details/Details';
import Category from './Components/Category/Category';
import Pro_Page from './Components/ProPage/ProPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cart from './Components/Cart/Cart';
import ConfirmOrder from './Components/ConfirmOrder/ConfirmOrder';
import More from './Components/More/More';
import EditProfile from './Components/EditProfile/Edit_Profile';
import MyAddress from './Components/MyAddress/MyAddress';
import Orders from './Components/Orders/Orders';
import Order2 from './Components/Orders/Order2';
import Email from './Components/SignUP/Emial/Email';
import Password from './Components/SignUP/Password/Password';
import OTP from './Components/SignUP/OPTVerification/OTP';
import NewPassword from './Components/SignUP/NewPassword/NewPassword';
import ChatPage from './Components/ChatPage/ChatPage';
import Notification from './Components/Notification/Notification';
import AddNewAddress from './Components/MyAddress/AddNewAddress.jsx';


function App() {
  return (
    <>
        <Router>
            <Routes>
                <Route path="/" element={<Splash/>} />
                <Route path="/Home" element={<Home />} />
                <Route path='/Details' element={<Details/>}/>
                <Route path='/Category' element={<Category/>}/>
                <Route path='/Pro_Page' element={<Pro_Page/>}/>
                <Route path='/Cart' element={<Cart/>}/>
                <Route path='/ConfirmOrder' element={<ConfirmOrder/>}/>
                <Route path='/More' element={<More/>}/>
                <Route path='/EditProfile' element={<EditProfile/>}/>
                <Route path='/MyAddress' element={<MyAddress/>}/>
                <Route path='/AddNewAddress' element={<AddNewAddress/>}/>

                <Route path='/Orders' element={<Orders/>}/>
                <Route path='/Order2' element={<Order2/>}/>
                <Route path='/Email' element={<Email/>}/>
                <Route path='/Password' element={<Password/>}/>
                <Route path='/OTP' element={<OTP/>}/>
                <Route path='/NewPassword' element={<NewPassword/>}/>
                <Route path='/ChatPage' element={<ChatPage/>}/>
                <Route path='/Notification' element={<Notification/>}/>
                

            </Routes>
        </Router>
    </>
  );
}

export default App;


