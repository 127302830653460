import "bootstrap/dist/css/bootstrap.min.css";
import "../Cart/Cart.css";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCalendar } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import CartCard from "../Cards/Cart_Card/CartCard";

import "react-datepicker/dist/react-datepicker.css";
import React, { useState } from "react";

import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

function Cart() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  return (
    <>
      <Navbar />
      <div className="container-fluid px-0">
        {/* section 1 */}
        <div className="row mt-3 mb-3">
          <div className="col-10">
            <ol className="breadcrumb mb-0 ">
              <li className="breadcrumb-item">
                <Link id="link" to="/Home">
                  Home
                </Link>
              </li>

              <li className="breadcrumb-item">
                <Link id="link" to="/Cart">
                  Cart
                </Link>
              </li>
            </ol>
          </div>
          <div className="col-2">
            <FontAwesomeIcon id="bell" icon={faBell} />
          </div>
        </div>
        <div className="container-fluid">
          <div className="row ">
            {/* Cards */}
            <CartCard
              imgSrc={"assets/p1.png"}
              alt={"img 1"}
              ProName={"Image"}
              POne={"200"}
              PTwo={"245"}
            />
            <CartCard
              imgSrc={"assets/p1.png"}
              alt={"img 1"}
              ProName={"Image"}
              POne={"200"}
              PTwo={"245"}
            />
            <CartCard
              imgSrc={"assets/p1.png"}
              alt={"img 1"}
              ProName={"Image"}
              POne={"200"}
              PTwo={"245"}
            />
          </div>

          {/* Add more button */}
          <div>
            <Link className="AddButton my-4" to="/Pro_Page">
              <button>Add More Product</button>
            </Link>
          </div>

          {/* Location head*/}

          <div className="row align-items-center Calandar mt-4 mb-4">
            <div className="text-start">Expected Date and Time</div>
            <div className="input-group mt-4">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faCalendar} />
              </div>
              <input
                type="date"
                className="form-control"
                placeholder="Select Date"
                defaultValue={new Date().toISOString().substr(0, 10)}
              />
            </div>
          </div>

          {/* Time row 1 */}

          <div className="Time mb-4">
            <button>8 AM-11 AM</button>
            <button>11 AM-12 PM</button>
            <button>12 PM-2PM</button>
            <button>2 PM-4 PM</button>
            <button>4 PM-6 PM</button>
            <button>6 PM-8 PM</button>
          </div>
          {/* Location head*/}

          <div className="row align-items-center lochead mt-2">
            <div className="col-6 text-start">Delivery location</div>
            <Link
              to={"/MyAddress"}
              className="col-6 lochead text-decoration-none"
            >
              <div className=" text-end">Change location</div>
            </Link>
          </div>

          {/* Location*/}

          <div className="row align-items-center location-row">
            <div className="col-2">
              <FontAwesomeIcon icon={faLocationDot} className="location-icon" />
            </div>
            <div className="col-8">
              <p className="location-address CLA mt-3">
                32 Llanberis Close, Tonteg, CF38 1HR
              </p>
            </div>
          </div>

          {/* Subtotal */}
          <div className="row align-items-center subtotal mt-2">
            <div className="col-6 text-start">Delivery location</div>
            <div className="col-6 text-end">Change location</div>
          </div>

          {/* Delivery Charges */}

          <div className="row align-items-center delivery mt-2">
            <div className="col-6 text-start">Delivery location</div>
            <div className="col-6 text-end">Change location</div>
          </div>

          {/* Total */}
          <div className="row align-items-center total mt-2">
            <div className="col-6 text-start">Delivery location</div>
            <div className="col-6 text-end">Change location</div>
          </div>
          <hr />
        </div>

        {/* Add more button */}
        <div>
          <Link className="OrderButton" to="/ConfirmOrder">
            <button>Place Order</button>
          </Link>
        </div>
      </div>
      <div style={{ height: "60px" }}>&nbsp;</div>
      <Toolbar />
    </>
  );
}

export default Cart;
