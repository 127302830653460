import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Link } from "react-router-dom";
import "./C_Card.css";
function C_Card({ imgSrc, alttext, text }) {
  return (
    <>
      {/* Wrap the Product card with Link for navigaton */}
      <div className="PROCARD">
        <div className="col-6 justify-content-center CCard">
          <div className="Product my-1 p-3 pb-2">
            <img className="CImg " src={imgSrc} alt={alttext} />
            <p className="CName">{text}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default C_Card;
