import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons"; // Import Google icon
import { Link } from "react-router-dom";
import "./Email.css";
import { useState } from "react";

function Email() {
  const [email, setEmail] = useState("");

  return (
    <>
      <div className="container-fluid mt-2" style={{ fontSize: "20px" }}>
        <Link to={"/Home"} className="text-decoration-none text-black">
          👈 back to home
        </Link>
      </div>
      <div className="container-fluid px-0">
        {/* section 1 */}
        <div className="d-flex align-items-center justify-content-center">
          <dotlottie-player
            src="https://lottie.host/b2cf5be9-a9c9-4fc0-92a2-1122522e3524/QmQ03jesk9.json"
            background="transparent"
            speed="0.3"
            style={{ width: "250px", height: "300px" }}
            autoplay
          ></dotlottie-player>
        </div>
        {/* section 2 */}
        <div className="container-fluid">
          <h3>Enter Your Email</h3>
          <p>
            We need to verify you. We will send you a one-time verification
            code.
          </p>
          <form>
            <div className="input-group">
              {/* Input with Google icon */}
              <span className="input-group-text">
                <FontAwesomeIcon icon={faGoogle} style={{ color: "#4285F4" }} />
              </span>
              <input
                type="email"
                placeholder="Enter your email"
                className="form-control"
                required
                value={email}
                onChange={(e) => {
                  const button = document.querySelector(".Submit");
                  const emailValue = e.target.value;
                  setEmail(emailValue);
                  button.disabled =
                    !e.target.validity.valid ||
                    !emailValue.includes("@") ||
                    !emailValue.includes(".com");
                }}
              />
            </div>
            {window.User.some((user) => user.email === email) ? (
              <Link to="/Password" state={{ email }}>
                <button
                  className="Submit mt-3"
                  disabled={!window.User.some((user) => user.email === email)}
                >
                  Next
                </button>
              </Link>
            ) : (
              <Link to="/OTP" state={{ email }}>
                <button
                  className="Submit mt-3"
                  disabled={window.User.some((user) => user.email === email)}
                >
                  Next
                </button>
              </Link>
            )}
          </form>
        </div>
      </div>
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default Email;
