import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Link } from "react-router-dom";
import "../P_Card/P_Card.css";

function P_Card({ imgSrc, alttext, text, price }) {
  return (
    <>
      {/* Wrap the Product card with Link for navigation */}

      {/* adjust p to mintain the gap between card */}
      <div className="col-6 ProCard">
        <Link to="/Details" style={{ textDecoration: "none" }}>
          <div className="PCard p-1  justify-content-center">
            <img className=" " src={imgSrc} alt={alttext} />
            <div className="PName">{text}</div>
            <div className="PPrice">Rs. {price}</div>
          </div>
        </Link>
        <div>
          <button className="CB">add to bag</button>
        </div>
      </div>
    </>
  );
}

export default P_Card;
