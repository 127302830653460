import "bootstrap/dist/css/bootstrap.min.css";
import "./RecProduct.css";
import { useState } from "react";

function RecProducts({ imgSrc, alttext, RecName, POne, PTwo }) {
  const [isClicked, setIsClicked] = useState(false);

  const handleCartClick = () => {
    setIsClicked(true);

    // Reset the animation after it's complete
    setTimeout(() => {
      setIsClicked(false);
    }, 300);
  };

  return (
    <>
      {/* Recommended Product */}
      <div className="row RP">
        <div className="col-4">
          <img className="RPI" src={imgSrc} alt={alttext} />
        </div>
        <div className="col-6 d-flex flex-column justify-content-center">
          <div className="RPN">{RecName}</div>
          <div className="RPSP">
            <strike>{POne}</strike>
          </div>
          <div className="RPP">
            <strong>{PTwo}</strong>
          </div>
        </div>
        <div className="col-2">
          <button
            className={`RB d-flex ${isClicked ? "clicked" : ""}`}
            onClick={handleCartClick}
          >
            🛒
          </button>
        </div>
      </div>
      <hr />
    </>
  );
}

export default RecProducts;
