import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import "./ProductDetail.css";
import Navbar from "../Navbar/Navbar";
import RecProducts from "../Cards/RecomendedProducts/RecProduct";

function Details() {
  // Fetch the product details from window.CP
  const product = window.Product[0]; // Assuming we are working with the first product
  const images = product.img.split(","); // Split the images by ','

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="row d-flex align-items-center justify-content-between Details">
          <div className="col-auto">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link id="link" to="/Pro_Page">
                  Products
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/Details">
                  Product Detail
                </Link>
              </li>
            </ol>
          </div>
        </div>

        {/* Hero Image */}
        <div className="ProductD d-flex justify-content-center">
          <img src={`/assets/product/${images[0]}`} alt={product.name} />
        </div>

        {/* Product Name */}
        <p className="PN">{product.name}</p>

        {/* Additional Images */}

        <div className="sub-image d-flex">
          {images.map((img, index) => (
            <img
              key={index}
              src={`/assets/product/${img}`}
              alt={`Sub product ${index + 1}`}
              className="me-2"
            />
          ))}
        </div>

        {/* Quantity and Price */}
        <div className="PP d-flex justify-content-between">
          <p id="quantity">1KG</p>
          <p id="price">{product.price} Rs</p>
        </div>

        {/* Order Button */}
        <div className="PO">
          <button>Order now</button>
        </div>

        {/* Product Description */}
        <div className="PC mt-2">
          <p id="CN">Category ID: {product.cat_id}</p>
          <p id="CD">{product.desc}</p>
        </div>

        <p id="RN">You can also check these items:</p>
        {/* Recommended Products */}
        {[...Array(4)].map((_, index) => (
          <RecProducts
            key={index}
            imgSrc={"assets/logo.png"}
            alt={"Recommended product"}
            RecName={"Recommended Item"}
            POne={"200"}
            PTwo={"245"}
          />
        ))}
      </div>
    </>
  );
}

export default Details;
