import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEnvelope,
  faMessage,
} from "@fortawesome/free-regular-svg-icons";
import {
  faPencil,
  faLocationDot,
  faCartShopping,
  faPhone,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import "./More.css";
import ProfileListCard from "../Cards/ProfileListCard/ProfileListCard";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

function More() {
  return (
    <>
      <Navbar />

      {/* section 1 */}
      <div className="container-fluid px-0">
        <div className="row mt-3">
          <div className="col-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link id="link" to="/Home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/More">
                  More
                </Link>
              </li>
            </ol>
          </div>
          <div className="col-2">
            <FontAwesomeIcon id="bell" icon={faBell} />
          </div>
        </div>

        {/* section 2 */}
        <div className="row align-items-center Profile mt-3 ">
          <div className="col-1 ProfilePic">
            <img src="/assets/profile.jpeg" alt="Profile" />
          </div>
          <div className="col-2 mx-1"></div>
          <div className="col-8 about ">
            <div className="UserName">User123</div>
            <div className="Phno">03xxxxxxxxx</div>
          </div>
        </div>

        {/* Section 3 */}
        <Link
          to="/EditProfile"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProfileListCard
            icon={faPencil}
            list_Name={"Edit Profile"}
            IC={"#4A90E2"}
          />
        </Link>
        <Link
          to={"/MyAddress"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProfileListCard icon={faLocationDot} list_Name={"My Address"} />
        </Link>
        <Link to={"/Orders"} style={{ textDecoration: "none", color: "black" }}>
          <ProfileListCard icon={faCartShopping} list_Name={"My Orders"} />
        </Link>

        <Link
          to={"/ChatPage"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProfileListCard
            icon={faMessage}
            list_Name={"Chat with us"}
            IC={"green"}
          />
        </Link>

        <Link style={{ textDecoration: "none", color: "black" }}>
          <ProfileListCard
            icon={faPhone}
            list_Name={"Talk to our Support"}
            IC={"orange"}
          />
        </Link>

        <Link style={{ textDecoration: "none", color: "black" }}>
          <ProfileListCard icon={faEnvelope} list_Name={"Mail to us"} />
        </Link>
        <Link style={{ textDecoration: "none", color: "black" }}>
          <ProfileListCard
            icon={faFacebookF}
            list_Name={"Message to facebook page"}
            IC={"#4D91E5"}
          />
        </Link>

        <Link style={{ textDecoration: "none", color: "black" }}>
          <ProfileListCard icon={faPowerOff} list_Name={"Log out"} IC={"red"} />
        </Link>
      </div>
      <Toolbar />
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default More;
