import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import "./NewPassword.css";

function Password() {
  const location = useLocation();
  const email = location.state.email;

  return (
    <>
      <div className="container-fluid mt-2" style={{ fontSize: "20px" }}>
        <Link to={"/Home"} className="text-decoration-none text-black">
          👈 back to home
        </Link>
      </div>
      <div className="container-fluid px-0">
        {/* section 1 */}
        <div className="d-flex align-items-center justify-content-center">
          <dotlottie-player
            src="https://lottie.host/a29b1b22-53d3-48ca-88fb-faca4c762302/qo83s8swRw.json"
            background="transparent"
            speed="1"
            style={{ width: "250px", height: "300px" }}
            loop
            autoplay
          ></dotlottie-player>
        </div>
        {/* section 2 */}
        <div className="container-fluid mt-4">
          <h3>Enter Your New Password</h3>
          <p>Please enter the password.</p>

          <small className="text-danger">*min length 3 characters</small>
          <form>
            Your Email: {email};
            <div className="input-group">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faKey} />
              </span>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                className="form-control"
                required
                minLength="3"
                onChange={(e) => {
                  const confirmPwdInput =
                    document.getElementById("confirm-password");
                  const submitButton = document.querySelector(".Submit");
                  const pwdValue = e.target.value;
                  const confirmPwdValue = confirmPwdInput.value;

                  submitButton.disabled =
                    !e.target.validity.valid ||
                    pwdValue.length < 3 ||
                    confirmPwdValue !== pwdValue;
                }}
              />
            </div>
            <div className="input-group">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faKey} />
              </span>
              <input
                type="password"
                id="confirm-password"
                placeholder="Confirm your password"
                className="form-control"
                required
                minLength="3"
                onChange={(e) => {
                  const passwordInput = document.getElementById("password");
                  const submitButton = document.querySelector(".Submit");
                  const confirmPwdValue = e.target.value;
                  const pwdValue = passwordInput.value;

                  submitButton.disabled =
                    !e.target.validity.valid ||
                    confirmPwdValue.length < 3 ||
                    confirmPwdValue !== pwdValue;
                }}
              />
            </div>
            <Link to="/Home">
              <button className="Submit mt-3" disabled>
                &nbsp;&nbsp;Login&nbsp;&nbsp;
              </button>
            </Link>
          </form>
        </div>
      </div>
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default Password;
