import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import "./Password.css";

function Password() {
  const location = useLocation();
  const email = location.state.email;
  const user = window.User.find((user) => user.email === email);
  const userName = user ? user.name : "Unknown User";
  return (
    <>
      <div className="container-fluid mt-2" style={{ fontSize: "20px" }}>
        {userName}
        <Link to={"/Home"} className="text-decoration-none text-black">
          👈 back to home
        </Link>
      </div>
      <div className="container-fluid px-0">
        {/* section 1 */}
        <div className="d-flex align-items-center justify-content-center">
          <dotlottie-player
            src="https://lottie.host/a29b1b22-53d3-48ca-88fb-faca4c762302/qo83s8swRw.json"
            background="transparent"
            speed="1"
            style={{ width: "250px", height: "300px" }}
            loop
            autoplay
          ></dotlottie-player>
        </div>
        {/* section 2 */}
        <div className="container-fluid mt-4">
          <h3>Enter Your Password</h3>
          <p>Please enter the password to proceed.</p>
          <small className="text-danger">*min length 3 characters</small>
          <form>
            <div className="input-group mb-3">
              <input
                type="email"
                className="form-control"
                value={email}
                readOnly
              />
            </div>
            <div className="input-group">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faKey} />
              </span>
              <input
                type="password"
                placeholder="Enter your password"
                className="form-control"
                required
                minLength="3"
                onChange={(e) => {
                  const button = document.querySelector(".Submit");
                  const pwdValue = e.target.value;

                  // Find the user with the given email
                  const user = window.User.find((user) => user.email === email);

                  if (user && pwdValue === user.password) {
                    button.disabled = false;
                    e.target.setCustomValidity("");
                  } else {
                    button.disabled = true;
                    e.target.setCustomValidity("Wrong password");
                    e.target.reportValidity();
                  }
                }}
              />
            </div>

            <Link state={{ name: userName }} to="/Home">
              <button className="Submit mt-3" disabled>
                &nbsp;&nbsp;Login&nbsp;&nbsp;
              </button>
            </Link>
          </form>
        </div>
        <Link
          to="/OTP"
          state={{ email: email }}
          className="text-decoration-none"
        >
          <p className="mt-2 text-center text-warning">Forgot Password?</p>
        </Link>
      </div>
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default Password;
