import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import "./OTP.css";

function OTP() {
  const location = useLocation();
  const email = location.state?.email || ""; // Extract email, fallback to an empty string if undefined

  return (
    <>
      <div className="container-fluid mt-2" style={{ fontSize: "20px" }}>
        <Link to={"/Home"} className="text-decoration-none text-black">
          👈 back to home
        </Link>
      </div>
      <div className="container-fluid px-0">
        {/* section 1 */}
        <div className="d-flex align-items-center justify-content-center">
          <dotlottie-player
            src="https://lottie.host/c6eb3d1b-aa83-46f3-be1e-3de7aa3d6af6/YZXbfAPwMJ.lottie"
            background="transparent"
            speed="1"
            style={{ width: "250px", height: "300px" }}
            loop
            autoplay
          ></dotlottie-player>
        </div>
        {/* section 2 */}
        <div className="container-fluid mt-4">
          <h2>Enter OTP sent to:</h2>
          <form>
            <div className="input-group">
              {/* Input for OTP */}
              <span className="input-group-text">
                <FontAwesomeIcon icon={faKey} />
              </span>
              <input
                type="text"
                placeholder="Enter OTP"
                className="form-control"
                required
              />
            </div>
            Your Email: {email};
            <Link to="/NewPassword" state={{ email: email }}>
              <button className="Submit mt-3">
                &nbsp;&nbsp;Next&nbsp;&nbsp;
              </button>
            </Link>
          </form>
          <div className="d-flex align-items-center ">
            <button className="OTPbtn">Resend OTP</button>
          </div>
        </div>
      </div>
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default OTP;
