import "bootstrap/dist/css/bootstrap.min.css";
import "./OrderCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons"; // Import specific icons

function OrderCard({ O_No, O_Date, O_State, O_Price }) {
  const getStatusClass = (state) => {
    const lowerCaseState = state.toLowerCase();
    switch (lowerCaseState) {
      case "delivered":
        return "delivered";
      case "cancelled":
        return "cancelled";
      case "Ordered":
        return "Ordered";
      case "Shipping":
        return "Shipping";
      case "Ordered":
        return "Ordered";

      default:
        return "";
    }
  };

  return (
    <>
      {/* Order Card */}
      <div className="row align-items-center order-card">
        <div className="o_icon col-2 d-flex align-items-center justify-content-center mx-2">
          <FontAwesomeIcon icon={faBasketShopping} />
        </div>
        <div className="col-7 d-flex flex-column justify-content-center">
          <div className="Order_no"> {O_No}</div>
          <div className={`Order_state ${getStatusClass(O_State)}`}>
            {O_State}
          </div>
          <div className="Order_date">{O_Date}</div>
        </div>
        <div className="Order_price col-3 d-flex align-items-center">
          Rs. {O_Price}
        </div>
      </div>

      <hr />
    </>
  );
}

export default OrderCard;
