import "bootstrap/dist/css/bootstrap.min.css";
import "./CartCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"; // Import specific icons

function CartCard({ imgSrc, alttext, ProName, POne, PTwo }) {
  return (
    <>
      {/* Recommended Product */}
      <div className="row CartP align-items-center mt-2">
        {/* Image Section */}
        <div className="col-4">
          <img className="CartPI" src={imgSrc} alt={alttext} />
        </div>

        {/* Product Details Section */}
        <div className="col-8 d-flex flex-column justify-content-center">
          <div className="CartPN"> {ProName}</div>

          <div className="CartPOne">
            <strike> Rs.{POne}</strike>
          </div>

          <div className="row align-items-center CartPP">
            <div className="col-6">
              <strong> Rs.{PTwo}</strong>
            </div>
            <div className="col-6 d-flex align-items-center gap-2 CCardBtn">
              {/* Quantity Adjustment Buttons */}
              <button className="btn btnM">
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <span>1</span>
              <button className="btn btnP">
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default CartCard;
