import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/Home.css";
import Navbar from "../Navbar/Navbar";
import { useLocation, Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import P_Card from "../Cards/P_Card/P_Card";

function Pro_Page() {
  // Extract the query parameter from the URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCatId = parseInt(queryParams.get("cat_id"));

  // Filter products based on the selected category ID
  const filteredProducts = window.Product.filter(
    (item) => item.cat_id === selectedCatId
  );

  const catIdParam = queryParams.get("cat_id");

  return (
    <>
      <Navbar />
      <div>selectedCatId: {selectedCatId}</div>
      <div className="container-fluid px-0">
        {/* Breadcrumb Section */}
        <div className="row d-flex align-items-center justify-content-between Details mx-1 m-3">
          <div className="col-auto">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link id="link" to="/Category">
                  Category
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/Pro_Page">
                  Products
                </Link>
              </li>
            </ol>
          </div>
        </div>

        {/* Product Display Section */}
        <div className="container-fluid">
          <div className="row">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((item, index) => (
                <P_Card
                  key={index}
                  imgSrc={`assets/Product/${item.img}`}
                  alt={item.alt}
                  text={item.name}
                  price={item.price}
                />
              ))
            ) : (
              <div className="col-12 text-center">
                <h5>No products available for this category.</h5>
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ height: "60px" }}>&nbsp;</div>
      <Toolbar />
    </>
  );
}

export default Pro_Page;
