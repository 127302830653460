import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser } from "@fortawesome/free-regular-svg-icons";
import { faCamera, faLock, faPhone } from "@fortawesome/free-solid-svg-icons"; // Ensure correct import
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import "./Edit_Profile.css";

function EditProfile() {
  return (
    <>
      <Navbar />
      <div className="container-fluid px-0">
        {/* section 1 */}
        <div className="row ">
          <div className="col-10">
            <ol className="breadcrumb mb-3">
              <li className="breadcrumb-item">
                <Link id="link" to="/More">
                  More
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/EditProfile">
                  EditProfile
                </Link>
              </li>
            </ol>
          </div>
          <div className="col-2">
            <FontAwesomeIcon id="bell" icon={faBell} />
          </div>
        </div>

        {/* section 2 */}
        <div className="d-flex align-items-center justify-content-center position-relative">
          <img
            className="profile_pic"
            src="/assets/profile.jpeg"
            alt="Profile"
          />
          <button className="edit-button">
            <FontAwesomeIcon icon={faCamera} /> {/* Correct usage */}
          </button>
        </div>

        {/* section 3 */}
        <div>
          <div className="row Plist mt-4">
            <div className="col-3 d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className="col-9 d-flex flex-column">
              <label>Full Name</label>
              <input
                type="text"
                className="form-control p-0"
                placeholder="Enter full name"
              />
            </div>
          </div>
          <hr />

          <div className="row Plist mt-4">
            <div className="col-3 d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <div className="col-9 d-flex flex-column">
              <label>Password</label>
              <input
                type="password"
                className="form-control p-0"
                placeholder="Enter password"
              />
            </div>
          </div>
          <hr />

          <div className="row Plist mt-4">
            <div className="col-3 d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className="col-9 d-flex flex-column">
              <label>Phone Number</label>
              <input
                type="tel"
                className="form-control p-0"
                placeholder="Enter phone number"
              />
            </div>
          </div>
          <hr />
          <div>
            <Link className="OrderButton" to="/More">
              <button>Save</button>
            </Link>
          </div>
        </div>
        {/* section 4 */}
      </div>
      <Toolbar />
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default EditProfile;
